import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./LAII.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import LAIIPhoto1 from "../../../../res/Photos site/LA II/La-II.jpg"
import LAIIPhoto2 from "../../../../res/Photos site/LA II/Shafrazi_LA II-Keith Haring .jpg"
import LAIIPhoto3 from "../../../../res/Photos site/LA II/2.jpg"
import LAIIPhoto4 from "../../../../res/Photos site/LA II/LA II - Patti Astor by Eric Kroll.png"
import LAIIPhoto5 from "../../../../res/Photos site/LA II/LA II (2).jpg"
import LAIIPhoto6 from "../../../../res/Photos site/LA II/La II.jpg"
import LAIIPhoto7 from "../../../../res/Photos site/Photos Oeuvres Disponibles/LA II - Banana - 2003.png"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/laII/portrait.jpg'
const isBrowser = typeof window !== "undefined";
const infos ={
  path: "LAII",
  name: "LAII",
  description:"Angel Ortiz, alias LA II, est né en 1967 dans le Lower East Side à New York. Il est également connu sous les noms LA II, LA2 ou LA Rock, qui signifie \"Petit Ange\", que l’on voit souvent comme dans ses oeuvres d'art. LA II a commencé sa carrière lorsqu'il a rencontré Keith Haring en 1980 à l'âge de 13 ans. Étant l'un des graffeurs les plus reconnaissables du Lower East Side dans les années 1980, Ortiz a collaboré avec Keith Haring pour un certain nombre de projets. Après avoir quitté le lycée de Manhattan en 1983, il a voyagé dans le monde entier pour travailler avec Keith Haring jusqu'en 1985. LA II est pour Haring ce que Jean-Michel Basquiat était pour Andy Warhol. Aujourd'hui, LA II est considéré comme un artiste graffiti emblématique, exhumé de la puissance massive de l'oeuvre de Keith Haring.  Parmi ces noms figurent Richard Hamilton, un certain nombre de streets artistes comme Delta 2, Ero et Cindy Shaoul. LA II a notamment participé à bon nombres d’expositions chez Tony Shafrazi Gallery, New York City, avec Keith Haring en 1982, en 1983 aux côtés de Keith Haring à la Fun Gallery, New York City, à la Galerie Watari, Tokyo, Japon, à la Robert Fraser Gallery, Londres, Royaume-Uni, au Special Projects Paint Fiorucci, Milan, Italie, en 1990 Future Primeval Queens Museum, Flushing Meadows et Corona Park, New York avec Keith Haring, Follin Gallery, New York City en 2001, Clayton Patterson Outlaw Museum, New York City en 2002, LA II & Keith Haring, Due amici a New York en 2003, Scope Art Fair, Los Angeles, Californie en 2004, et Galleria Leonardo Galerie, Bolzano, Italie en 2005. Après la fin de sa collaboration avec Haring, LA II a continué à réaliser ses propres oeuvres en luttant pour faire connaître son travail passé et actuel. LA II vit et travaille aujourd’hui à New York.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de LAII.",
  photos: [
    { src: LAIIPhoto2, name: "LAII" },
    { src: LAIIPhoto5, name: "LAII" },
    { src: LAIIPhoto6, name: "LAII" },
  ],
  citations: []
};

const infos2 = {
  photos: [
    { src: LAIIPhoto7, name: "LA II", artist: "LA II\n(Angel ORTIZ dit, né en 1966)", title:"Banana, 2003", desc3:"167 x 147 cm", desc4:"65 3/4 x 57 7/8 in.", desc7: "- Collection particulière.", desc8:"<h4>-<i> Nos Fantômes - </i>exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021</h4>"},
  ],
};


const LAII = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>LAII <h2 style={{paddingLeft: "16px"}}>(Angel Ortiz - Américain)</h2></h1>
          <h3 className={NameWrapper} >1967 - 1998</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />

        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default LAII;