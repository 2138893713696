// extracted by mini-css-extract-plugin
export var ArtistDescription = "LAII-module--ArtistDescription--IxST6";
export var ArtistInfos = "LAII-module--ArtistInfos--gYFk7";
export var ButtonWrapper = "LAII-module--ButtonWrapper --zvnzB";
export var CardWrapper = "LAII-module--CardWrapper--Liwog";
export var CarrouselWrapper2 = "LAII-module--CarrouselWrapper2--Fc20n";
export var Citations = "LAII-module--Citations--Tvx6i";
export var DescriptionWrapper = "LAII-module--DescriptionWrapper--nDYWW";
export var ImageWrapper = "LAII-module--ImageWrapper--CuBfO";
export var LinkWrapper = "LAII-module--LinkWrapper--qQKSP";
export var MobileProtrait = "LAII-module--MobileProtrait--SkYHv";
export var More = "LAII-module--More--wmSEL";
export var MoreButton = "LAII-module--MoreButton--Ex8zE";
export var NameWrapper = "LAII-module--NameWrapper--xIpXf";
export var PdpWrapper = "LAII-module--PdpWrapper--Zpd-3";
export var PhotosWrapper = "LAII-module--PhotosWrapper--aXESW";
export var ProfilWrapper = "LAII-module--ProfilWrapper--fCq9T";
export var TitleWrapper = "LAII-module--TitleWrapper--GTsg3";
export var Wrapper = "LAII-module--Wrapper--LX1iu";